<template>
  <b-container class="p-4">
    <b-row>
      <b-col class="mb-2 d-flex align-items-center justify-content-between"><h4>Clients</h4></b-col>
    </b-row>

    <b-row>
      <b-col class="mb-2">
        <b-button variant="primary" to="/onboarding/client/edit">
          <feather type="plus"></feather>
          New client
        </b-button>

        <b-button class="ml-3" variant="outline-primary" v-b-modal.modal-filters>
          <feather type="filter"></feather>
          Filter list
        </b-button>

        <b-badge class="ml-3" v-if="showResetButton" role="button"
                 variant="danger" @click="resetFilters()">X reset filters
        </b-badge>
        <b-badge v-for="(value, key) in appliedFilters" :key="key" class="ml-3">{{ key }} = {{ value }}</b-badge>

        <b-button variant="outline-secondary" class="float-right" @click="refreshData" :disabled="isLoading">
          <b-spinner small v-if="isLoading"/>
          <feather v-else type="refresh-cw"></feather>
        </b-button>

        <b-modal id="modal-filters" title="Filter clients" size="lg">
          <b-form @submit="applyFilters()">

            <b-row>
              <b-col cols="12">
                <b-form-group label="Client ID" label-for="input-client-id">
                  <b-form-input id="input-client-id" v-model="filters.id" :disabled="loading" type="text"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Client Mode" label-for="input-client-mode">
                  <b-form-select id="input-client-mode" v-model="filters.client_mode" :disabled="loading"
                                 :options="filtersData.clientModes"></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Unit" label-for="input-unit">
                  <b-form-select id="input-unit" v-model="filters.unit" :disabled="loading"
                                 :options="filtersData.units"></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                  <b-form-group label="Parent Client">
                    <client-multi-select :filters="parentClientFilters"
                                         :selected-value="selectedValues.parent"
                                         @selection-change="onParentClientFilterSelectionChange"></client-multi-select>
                  </b-form-group>
                </b-col>

              <b-col cols="12">
                <b-form-group label="Clients">
                  <client-multi-select :config="multiClientsConfig"
                                       :selected-value="selectedValues.clients"
                                       @selection-change="onClientFilterSelectionChange"></client-multi-select>

                </b-form-group>
              </b-col>
            </b-row>

          </b-form>

          <template #modal-footer>
            <b-button size="sm" variant="success" @click="applyFilters()">Apply</b-button>
          </template>
        </b-modal>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12">
        <b-tabs content-class="mt-3">
          <b-tab title="Active" active>
            <b-row class="mt-3">
              <b-col cols="12">
                <client-table
                  :config="clientTable.config"
                  :filters="activeClientsFilters"
                  @loading="onActiveClientsLoadingChange"></client-table>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Inactive">
            <b-row class="mt-3">
              <b-col cols="12">
                <client-table
                  :config="clientTable.config"
                  :filters="inactiveClientsFilters"
                  @loading="onInactiveClientsLoadingChange"></client-table>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import ClientTable from '@/components/Clients/ClientTable.vue';
import ClientMultiSelect from '@/components/Clients/ClientMultiSelect.vue';

const StorageName = 'clientListFilters';
export default {
  name: 'ListClients',
  components: { ClientMultiSelect, ClientTable },
  data() {
    return {
      loading: false,
      appliedFilters: {},
      filters: {
        id: null,
        parent_id: null,
        unit: null,
        client_mode: null,
      },
      filtersData: {
        clientModes: [
          { value: null, text: '' },
          { value: 'NORMAL', text: 'Normal' },
          { value: 'DEMO', text: 'Demo' },
          { value: 'TEST', text: 'Test' },
        ],
        units: [
          { value: null, text: '' },
          { value: 'dpt', text: 'DPT' },
          { value: 'bloom', text: 'Bloom' },
          { value: 'mind', text: 'Mind' },
          { value: 'move', text: 'Move' },
          { value: 'dpt_go', text: 'DPT Go' },
        ],
      },
      selectedValues: {
        clients: null,
        parent: null,
      },
      multiClientsConfig: {
        multiple: true,
        limit: 3,
      },
      parentClientFilters: {
        is_parent: true,
      },
      clientTable: {
        config: {
          searchEnabled: true,
        },
        active: {
          loading: false,
        },
        inactive: {
          loading: false,
        },
      },
    };
  },
  computed: {
    showResetButton() {
      return Object.values(this.appliedFilters).some(value => !!value);
    },
    isLoading() {
      return this.clientTable.active.loading && this.clientTable.inactive.loading;
    },
    activeClientsFilters() {
      return {
        active: true,
        client_mode: this.appliedFilters.client_mode,
        id: this.appliedFilters.id,
        parent_id: this.appliedFilters.parent_id,
        unit: this.appliedFilters.unit,
      };
    },
    inactiveClientsFilters() {
      return {
        active: false,
        client_mode: this.appliedFilters.client_mode,
        id: this.appliedFilters.id,
        parent_id: this.appliedFilters.parent_id,
        unit: this.appliedFilters.unit,
      };
    },
  },
  created() {
    // Session storage is used to persist the multi select components selected options.
    // This way, if the user refresh the page we don't lose the already selected options.

    const values = JSON.parse(sessionStorage.getItem(StorageName));
    if (values) {
      this.selectedValues = values;
    }

    this.applyFiltersFromRoute();

    // erase selected values when the correspondent query parameter is missing
    if (!this.appliedFilters.id) {
      this.selectedValues.clients = null;
    }

    if (!this.appliedFilters.parent_id) {
      this.selectedValues.parent = null;
    }
  },
  beforeDestroy() {
    sessionStorage.setItem(StorageName, null);
  },
  methods: {
    applyFiltersFromRoute() {
      Object.keys(this.filters).forEach(key => {
        if (!this.$route?.query?.[key]) return;

        this.appliedFilters[key] = this.$route.query[key];
        this.filters[key] = this.$route.query[key];
      });
    },
    applyFilters() {
      this.updateAppliedFilters();

      this.$router.replace({
        ...this.$router?.currentRoute,
        query: { ...this.appliedFilters },
      });

      this.$bvModal.hide('modal-filters');
    },
    updateAppliedFilters() {
      this.appliedFilters = {};
      Object.keys(this.filters).forEach(key => {
        const value = this.filters[key];
        if (value) this.appliedFilters[key] = value;
      });
    },
    clearFilters() {
      this.filters = {};
      this.appliedFilters = {};
      this.clearSelectedValues();

      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
    },
    clearSelectedValues() {
      this.selectedValues.parent = null;
      this.selectedValues.clients = null;
      sessionStorage.setItem(StorageName, null);
    },
    resetFilters() {
      this.clearFilters();
      this.$router.replace({ ...this.$router?.currentRoute, query: {} });
    },
    refreshData() {
      this.updateAppliedFilters();
    },
    onParentClientFilterSelectionChange(client) {
      this.selectedValues.parent = client;
      sessionStorage.setItem(StorageName, JSON.stringify(this.selectedValues));

      this.filters.parent_id = client ? client.value : client;
    },
    onClientFilterSelectionChange(clients) {
      this.selectedValues.clients = clients;
      sessionStorage.setItem(StorageName, JSON.stringify(this.selectedValues));

      if (!clients) {
        this.filters.id = clients;
        return;
      }

      if (Array.isArray(clients)) {
        this.filters.id = clients.map(item => item.value);
        return;
      }

      this.filters.id = clients.value;
    },
    onActiveClientsLoadingChange(loading) {
      this.clientTable.active.loading = loading;
    },
    onInactiveClientsLoadingChange(loading) {
      this.clientTable.inactive.loading = loading;
    },
  },
};
</script>
