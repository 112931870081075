<template>
  <div>
    <b-row v-if="searchEnabled">
      <b-col cols="3">
        <b-input-group>
          <b-input v-model="searchText" placeholder="Search" @keyup.enter="onSearchEnter" :disabled="isLoading"></b-input>
          <b-button class="ml-1" variant="primary" :disabled="isLoading" @click="onSearch">
            <b-icon icon="search" aria-label="Search"></b-icon>
          </b-button>
        </b-input-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mt-3">
        <b-table :fields="fields" :busy="isLoading" :items="list.items" class="min-height-500"
                 outlined sticky-header="800px" striped small
                 empty-text="No clients to show">

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(units)="data">
            <b-icon font-scale="1" class="mr-2" v-if="isUnitActive(data.item,'dpt')" icon="tablet-landscape"
                    title="DPT is active"></b-icon>
            <b-icon font-scale="1" class="mr-2" v-if="isUnitActive(data.item,'bloom')" icon="gender-female"
                    title="Bloom is active"></b-icon>
            <b-icon font-scale="1" class="mr-2" v-if="isUnitActive(data.item,'move')" icon="play-circle"
                    title="Move is active"></b-icon>
            <b-icon font-scale="1" class="mr-2" v-if="isUnitActive(data.item,'mind')" icon="bullseye"
                    title="Mind is active"></b-icon>
          </template>

          <template #cell(actions)="data">
            <b-button-group>
              <b-dropdown variant="light" right split :split-to="'/onboarding/client/edit/'+data.item.id">
                <template #button-content>
                  <feather type="edit-2"></feather>
                  Edit
                </template>
                <b-dropdown-item :to="`/onboarding/enrollments?client_id=${data.item.id}`">
                  <feather type="users"></feather>
                  Enrollments
                </b-dropdown-item>
                <b-dropdown-item :to="`/onboarding/clients/${data.item.id}/eligibility`">
                  <feather type="user-check"></feather>
                  Eligibility configs
                </b-dropdown-item>
                <b-dropdown-item :to="`/onboarding/integrations/${data.item.id}`">
                  <feather type="shuffle"></feather>
                  SSO & External Eligibility
                </b-dropdown-item>
                <b-dropdown-item :to="`/onboarding/clients/${data.item.id}/sso/integrations`">
                  <feather type="star"></feather>
                  [New] SAML SSO Integrations
                </b-dropdown-item>
                <b-dropdown-item :to="`/onboarding/clients/${data.item.id}/billing`">
                  <feather type="dollar-sign"></feather>
                  Billing
                </b-dropdown-item>
                <b-dropdown-item :to="`/onboarding/eligibility/${data.item.id}/checker`">
                  <feather type="check-square"></feather>
                  Test eligibility
                </b-dropdown-item>
                <b-dropdown-item :to="`/onboarding/clients/${data.item.id}/referrals`">
                  <feather type="map"></feather>
                  Referral states configuration
                </b-dropdown-item>
                <b-dropdown-item :to="`/onboarding/clients/${data.item.id}/invitations`">
                  <feather type="send"></feather>
                  Member invitation
                </b-dropdown-item>
                <b-dropdown-item :to="`/client/${data.item.id}/manage-professionals`">
                  <feather type="briefcase"></feather> Manage professionals
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </template>

          <template #cell(display_name)="data">
            <span v-if="data.item.client_mode === 'NORMAL'" :class="`dot dot-green mr-2`" title="Normal client"></span>
            <span v-if="data.item.client_mode === 'DEMO'" :class="`dot dot-yellow mr-2`" title="Demo Client"></span>
            <span v-if="data.item.client_mode === 'TEST'" :class="`dot dot-blue mr-2`" title="Test Client"></span>
            {{ data.item.display_name }}
          </template>

          <template #cell(institution)="data">
            {{ data.item.institution_id }} | {{ data.item.reference }}
          </template>

        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="11" class="d-flex justify-content-center">
        <b-pagination
          :disabled="isLoading"
          v-model="list.page"
          :total-rows="list.total"
          :per-page="list.limit"
          @change="onChangePage"
          size="md">
        </b-pagination>
      </b-col>
      <b-col cols="1" class="d-flex justify-content-center">
        <b-form-select
            v-model="list.limit"
            @change="search()">
            <b-form-select-option :value="10"> 10 </b-form-select-option>
            <b-form-select-option :value="20"> 20 </b-form-select-option>
            <b-form-select-option :value="50"> 50 </b-form-select-option>
          </b-form-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ClientList',
  props: {
    config: {
      type: Object,
      default: () => ({ searchEnabled: false }),
    },
    filters: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      searchText: '',
      fields: [
        { key: 'id', label: '#' },
        { key: 'account_name', label: 'Account Name' },
        { key: 'display_name', label: 'Name' },
        { key: 'institution', label: 'Institution' },
        { key: 'units', label: 'Active units' },
        { key: 'actions', label: 'Actions' },
      ],
      list: {
        page: 1,
        limit: 10,
        items: [],
        total: 0,
      },
    };
  },
  computed: {
    searchEnabled() {
      return this.config.searchEnabled;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.$emit('loading', this.isLoading);

      try {
        const params = this.buildQueryParameters();

        const response = await this.$store.dispatch('Onboarding/fetchClients', { params });
        if (response) {
          this.list.total = response.total;
          this.list.items = this.parseItems(response.items);
        }
      } catch {
        this.$noty.error('An error occurred while fetching clients list');
      } finally {
        this.isLoading = false;
        this.$emit('loading', this.isLoading);
      }
    },
    buildQueryParameters() {
      const queryParams = {
        offset: (this.list.page - 1) * this.list.limit,
        limit: this.list.limit,
      };

      if (this.searchText) {
        queryParams.search = this.searchText;
      }

      Object.entries(this.filters).forEach(([ paramName, paramValue ]) => {
        queryParams[paramName] = paramValue;
      });

      return queryParams;
    },
    parseItems(items) {
      if (!items) {
        return [];
      }

      return items.map(item => ({
        ...item,
        account_name: item.configuration?.salesforce_account_name,
        client_mode: item.configuration?.client_mode,
      }));
    },
    isUnitActive(client, unit) {
      if (client.configuration && client.configuration[unit]) {
        return client.configuration[unit].enabled;
      }

      if (unit === 'bloom' && client.is_bloom) {
        return true;
      }

      if (unit === 'dpt' && !client.is_bloom) {
        return true;
      }

      return false;
    },
    resetPager() {
      this.list.page = 1;
    },
    search() {
      this.resetPager();
      this.fetchData();
    },
    onSearch() {
      this.search();
    },
    onSearchEnter(event) {
      if (event.key === 'Enter' || this.searchText === '') {
        this.search();
      }
    },
    onChangePage(page) {
      this.list.page = page;
      this.fetchData();
    },
  },
  watch: {
    filters: {
      handler() {
        this.resetPager();
        this.fetchData();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;

  &.dot-green {
    background-color: rgba(0, 128, 0, 0.76);
  }

  &.dot-yellow {
    background-color: rgb(246, 238, 6);
  }

  &.dot-blue {
    background-color: #0080ff;
  }
}

.min-height-500 {
  min-height: 500px;
}
</style>
